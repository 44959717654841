<template>
  <div class="main-content voice">
    <div class="main-wrapper">
      <mdc-layout-grid class="main-content-inner">
        <mdc-layout-cell>
          <my-profile-card v-if="otherPerson" :profile="otherPerson"></my-profile-card>
        </mdc-layout-cell>
        <mdc-layout-cell>
          <mdc-card>
            <mdc-card-header title="Scheduled Meeting"></mdc-card-header>
            <mdc-card-text>
              Date: <br>
              Time:
            </mdc-card-text>
          </mdc-card>
        </mdc-layout-cell>
      </mdc-layout-grid>

      <mdc-layout-grid>
        <mdc-layout-cell>
          <mdc-card>
            <mdc-card-header title="Voice"></mdc-card-header>
            <mdc-card-text>
              Click to start voice call<br>
              Status : {{status}}<br>
              <div v-if="conferenceCode">
                Dial in option 020 3871 3130<br>
                Conference Number : {{conferenceCode}}
              </div>
            </mdc-card-text>
            <mdc-card-actions>
              <mdc-button v-if="inConf" style="background-color: red;" raised @click="hangup">
                <mdc-button-icon slot="leading-icon">call_end</mdc-button-icon>
              </mdc-button>
              <mdc-button v-else raised @click="conf">
                <mdc-button-icon slot="leading-icon">call</mdc-button-icon>
                Voice
              </mdc-button>
            </mdc-card-actions>
          </mdc-card>
        </mdc-layout-cell>
      </mdc-layout-grid>

      <mdc-layout-grid>
        <mdc-layout-cell>
          <mdc-card>
            <mdc-card-header title="Video"></mdc-card-header>
            <mdc-card-text>Click to open a window for a video call</mdc-card-text>
            <mdc-card-actions>
              <mdc-button raised @click="videoWindow">
                <mdc-button-icon slot="leading-icon">video_call</mdc-button-icon>
                Video
              </mdc-button>
            </mdc-card-actions>
          </mdc-card>
        </mdc-layout-cell>
      </mdc-layout-grid>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import fb from '../store/firebase'
import MyProfileCard from '../components/MyProfileCard.vue'

export default {
  name: 'Voice',
  data () {
    return {
      device: null,
      connectionId: null,
      connection: null,
      inConf: false,
      status: 'Ready'
    }
  },
  components: {
    MyProfileCard
  },
  methods: {
    ...mapActions([
      'setPageLoad'
    ]),
    conf () {
      this.inConf = true
      const params = { To: 'room' + this.connectionId.substring(0, 8) }
      console.log('Calling ' + params.To + '...')
      this.device.connect(params)
    },
    hangup () {
      this.inConf = false
      this.device.disconnectAll()
    },
    videoWindow () {
      window.open('/twilio/' + this.connectionId.substring(0, 8), '_blank', 'location=yes,height=750,width=520,scrollbars=no,status=no')
    }
  },
  computed: {
    ...mapState([
      'user',
      'profile',
      'profiles',
      'connections'
    ]),
    otherPerson () {
      if (this.connection) {
        const users = this.connection.users
        const id = Object.keys(users).filter(key => key !== this.user.uid)
        const profileMap = new Map(this.profiles.map(i => [i.id, i]))
        return profileMap.get(id[0])
      }
      return null
    },
    conferenceCode () {
      if (!this.connectionId) {
        return null
      }
      const dialInCodes = {
        zWF78leA: '123456',
        '4tkMHWZg': '787878',
        qVW2N55d: '565656',
        '1wNSMFE1': '001122',
        PfChs6Ej: '212121'
      }
      const confId = this.connectionId.substring(0, 8)
      return dialInCodes[confId]
    }
  },
  async mounted () {
    const self = this
    setTimeout(() => {
      self.setPageLoad(false)
    }, 600)
    this.connectionId = this.$route.params.id

    if (this.connections && this.connectionId) {
      const connectionsMap = new Map(this.connections.map(i => [i.id, i]))
      this.connection = connectionsMap.get(this.connectionId)
    }

    const videoToken = fb.functions.httpsCallable('twilioToken')
    const res = await videoToken()
    const token = res.data.token

    const device = require('twilio-client').Device

    device.setup(token)

    device.on('error', (error) => {
      this.status = 'Error: ' + error.message
    })

    device.on('connect', (conn) => {
      this.status = 'In call'
    })

    device.on('disconnect', (conn) => {
      this.status = 'Ready'
    })

    this.device = device
  },
  destroyed () {
    this.device.destroy()
  },
  watch: {
    connections: {
      handler (newc, oldc) {
        if (this.connections && this.connectionId) {
          const connectionsMap = new Map(this.connections.map(i => [i.id, i]))
          this.connection = connectionsMap.get(this.connectionId)
        }
      },
      immediate: true
    }
  }

}
</script>

<style>
.voice .mdc-button .mdc-icon {
  margin-bottom: 6px;
  margin-right: 12px;
}
</style>
